.categoriesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.categoryItem {
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  color: var(--white);
  border: var(--line);
}