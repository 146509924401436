.mobileModalBackButton {
  color: var(--white);
}

.desktopModal {
  display: none;
}

.closeButton {
  border-radius: 50%;
  background: var(--white);
  border: none;
  width: 35px;
  height: 35px;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.mobileModal {
  display: block;
}

@media (min-width: 768px) {
  .desktopModal {
    display: block;
  }

  .mobileModal {
    display: none;
  }

  .closeButton {
    display: flex;
  }
}
