.fullScreenLoadContainer,
.absoluteLoadContainer {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--background-mask);
  width: 100%;
  height: 100%;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white);
}

.absoluteLoadContainer {
  position: absolute;
}

.content {
  text-align: center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
