.aboutWrapper {
  padding: 25px 50px 100px 25px;
  background: var(--grey-800);
  width: 100%;
  height: calc(100vh - var(--header-height));
  overflow: auto;
  border-top: 1px solid var(--weak-border-line-color);
}

.innerContainer {
  max-width: 800px;
  margin: 0 auto;
}

.about {
  color: white;
}

.aboutContainer {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--weak-border-line-color);
}

.list {
  list-style: disc;
  margin-left: 25px;
}

.list li {
  margin: 5px 0;
}

.collapsePanel {
  background: var(--grey-800);
  border-bottom: none !important;
}

.collapsePanel :global(.ant-collapse-header) {
  background: var(--grey-700) !important;
  color: white !important;
  font-weight: bold;
}

.collapseContent {
  color: white;
  padding: 15px;
}

.underlinedText {
  text-decoration: underline;
}