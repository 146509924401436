.buttonImageStyle {
  text-align: center;
  vertical-align: baseline;
}

button.searchHeaderBtn {
  flex: none;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: none;
}

div.checkBoxGroup {
  max-height: calc(100vh - var(--bottom-of-desktop-dropdown-filters));
  overflow: auto;
  padding: 10px;
}

.searchHeaderBtn:global(.ant-btn) {
  background: var(--blur-color);
  backdrop-filter: blur(10px);
  border: none;
  box-shadow: none;
  border-radius: 5px;
  color: var(--white);
}

.searchHeaderBtn:global(.ant-btn:hover) {
  background: var(--grey-600);
  backdrop-filter: blur(10px);
  border: none;
  box-shadow: none;
  border-radius: 5px;
  color: var(--white);
}

.inputBox {
  margin: 10px;
  margin-right: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.searchInput:global(.ant-input-group-wrapper) {
  display: block;
  background: var(--blur-color);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  overflow: hidden;
  padding: 5px;
}

.searchInput:global(.ant-input-group-wrapper .ant-input) {
  color: var(--weak-white);
  padding-left: 0;
}

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 5;
  transition: all 0.3s;
}

.clearIcon svg {
  /* I used nested styles to get to the svg element in ant design */
  width: 12px;
  height: 12px;
  /* 10px is not enough, because 12px */
}

.autocompleteFoundSearchItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: var(--white);
  padding: 10px 15px;
  cursor: pointer;
  background: var(--grey-600);
  border-bottom: var(--line);
  gap: 0;
  flex-direction: column;
  align-items: start;
}

.firstAutoCompleteItemInfo {
  gap: 10px;
}

.descriptionText {
  margin: 0;
  margin-left: 15px;
  color: var(--light-gray);
  font-size: 12px;
  display: flex;
}

.descriptionText p,
.descriptionText b {
  margin: 0;
  white-space: pre;
}

.autocompleteFoundSearchItem:last-child {
  border-bottom: none;
}

.foundSearchItemInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.foundSearchItemInfoImg {
  width: 10px;
  height: 15px;
  margin-right: 5px;
}

.foundSearchItemInfoText {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}

.foundSearchItemInfoText p,
.foundSearchItemInfoText b {
  margin: 0;
  white-space: pre;
}

.searchFilterBlock {
  display: flex;
  flex-direction: column;
  background: var(--grey-800);
  gap: 10px;
}

.searchFilterSelectBlock {
  display: flex;
  flex-direction: column;
  order: 2;
  gap: 10px;
  padding: 0 10px;
}

.footerFilters {
  order: 3;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resetButton {
  background: none;
  border: none;
  color: var(--white);
  margin-right: 5px;
  cursor: pointer;
}

label.mapFrameEvents {
  color: var(--white);
}

.searchDatePickerBlock {
  order: 1;
  position: relative;
  margin: 15px;
  background: var(--grey-800);
  border-radius: 5px;
  padding: 5px;
  border: var(--line);
}

.filtersButton {
  margin: 5px;
}

.filterTitle {
  color: var(--white);
  font-size: 14px;
}

button.filterCloseBtn {
  background: none;
  border: none;
}

button.filterCloseBtn:hover,
button.filterCloseBtn:active,
button.filterCloseBtn:focus {
  background: none;
}

.searchDatePickerButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: end;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--grey-500);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--white);
  cursor: pointer;
  z-index: 2;
}

button.categoriesCheckboxButton {
  display: flex;
  background: rgba(0, 0, 0, 0);
  border-radius: 5px;
  color: var(--white);
  border: var(--line);
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

button.categoriesCheckboxButton:focus,
button.categoriesCheckboxButton:hover,
button.categoriesCheckboxButton:active {
  background: var(--line-color);
  border: var(--line);
  color: var(--white);
}

.greenCircle {
  display: inline-block;
  background: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.greenCircle:empty {
  display: none;
}

.selectAll {
  color: var(--white);
  cursor: pointer;
}

.markAllCategoriesLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.markAllCategoriesBlock {
  display: flex;
  align-items: center;
}

.markAllCategoriesMark {
  background: var(--primary-color);
  border: 1px solid var(--grey-500);
  border-radius: 5px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.markAllCategoriesMark span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.markAllCategoriesMark svg {
  width: 10px;
  fill: var(--white);
}

button.applyCheckedList,
button.applyCheckedList:hover {
  background: none;
  box-shadow: none;
  border: none;
  color: var(--white);
}

.applyCheckedList:hover {
  background: none;
  color: white;
  border: none;
}

.checkBoxGroupRow {
  position: relative;
  padding: 10px 0;
  color: var(--white);
  border-bottom: var(--line);
}

.checkBoxGroupRow:last-child {
  border-bottom: none;
}

.checkBoxGroupRow label {
  color: var(--white);
}

.applyCheckedListClose {
  opacity: 0;
  z-index: -1;
}

.filterContainer {
  transform: translate(-100%, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--grey-800);
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 5;
}

.filterWrapperOpen {
  transform: translate(0, 0);
}

.filterHeader {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.checkBoxGroupPopupOverlay {
  padding-top: 0;
}

.filterHeaderWrapper {
  background: var(--grey-800);
}

button.filterCloseButton:active,
button.filterCloseButton:focus,
button.filterCloseButton:hover {
  position: absolute;
  right: 25px;
}

.wrapperDatePicker {
  background: var(--grey-700);
}

@media (max-width: 768px) {
  .fullModeContainer {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: var(--grey-700);
    padding-right: 10px;
  }

  .filterContent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .filterBody {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 25px;
  }

  .searchFilterSelectBlock {
    gap: 0;
  }

  .expandIcon {
    transition: all 0.3s;
  }

  .expandIconOpen {
    transform: translateY(-50%) rotateZ(90deg) !important;
  }

  .checkBoxGroupPopover {
    display: none !important;
  }

  .checkBoxGroup {
    width: 100%;
    max-height: 350px;
  }

  div.checkBoxCollapse {
    position: relative;
    display: block;
    background: var(--grey-800);
    border: none;
  }

  .checkBoxCollapse::after {
    content: "";
    position: absolute;
    width: 100vh;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    left: -10px;
    bottom: 0;
  }

  .checkBoxPanel {
    background: var(--grey-800);
    border: none !important;
  }

  .footerFilters {
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .checkBoxCollapse {
    display: none;
  }

  .searchInput:global(.ant-input-group-wrapper) {
    background: var(--line-color);
  }

  .inputBox {
    margin: 0;
    border: var(--line);
    border-radius: 5px;
  }

  .filterContainer {
    position: relative;
    transform: translate(0, 0);
  }

  .drawerContent {
    padding: 0;
  }

  .filterHeader {
    display: none;
  }

  .filterHeaderWrapper {
    display: none;
  }

  .container {
    flex-direction: column;
    padding: 10px 15px;
    background: var(--grey-700);
    align-items: unset;
  }

  button.searchHeaderBtn {
    display: none;
  }

  .searchFilterBlock {
    background: var(--grey-700);
  }

  .searchFilterSelectBlock {
    flex-direction: row;
    justify-content: space-between;
    order: 1;
    padding: 0;
  }

  .footerFilters {
    padding: 0;
    order: 3;
  }

  .searchDatePickerBlock {
    margin: 0;
    order: 2;
    border: none;
    padding: 0;
    background: none;
  }

  .wrapperDatePicker {
    background: none;
    order: 3;
  }
}
