.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: var(--grey-800);
  height: var(--header-height);
}

.logo {
  width: 170px;
}
