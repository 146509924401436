.pdfDocumentContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: white;
}

.pdfMap {
  position: relative;
  width: calc(var(--pdf-width) * 3);
  height: calc(var(--pdf-height) * 3);
}

.mapImage {
  width: 100%;
}

.logoImage {
  width: 170px;
  position: absolute;
  background: var(--grey-800);
}

.filters {
  display: flex;
  flex-direction: column;
  width: var(--pdf-width);
  height: var(--pdf-height);
}

.searchQuery {
  margin: 0;
}

.searchQueryTitle {
  font-weight: bold;
  margin: 0;
}

.selectedFilters {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.listStyle {
  padding: 5px;
}

.listItem {
  border-bottom: 1px solid var(--default-grey);
  width: fit-content;
}

.listHeader {
  font-weight: bold;
}

.filtersContainer {
  display: flex;
  align-items: start;
}

.filtersContainerHeader {
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  margin: 0;
}

div.modal {
  display: flex;
  flex-direction: column;
  top: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modalContent {
  text-align: center;
  color: var(--white);
}
