@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap);
:root {
  --primary-color: #059669;
  --opacity-primary-color: #0596694d;
  --blur-color: #1018286b;
  --default-grey: #c6c6c6;
  --light-gray: #b9b9b9;
  --grey-25: #fcfcfd;
  --grey-50: #f9fafb;
  --grey-100: #f2f4f7;
  --grey-200: #eaecf0;
  --grey-300: #d0d5dd;
  --grey-400: #98a2b3;
  --grey-500: #667085;
  --grey-600: #475467;
  --grey-700: #344054;
  --opacity-grey-700: #3440540d;
  --grey-750: #323d4f;
  --grey-800: #1d2939;
  --grey-900: #101828;
  --white: #ffffff;
  --background-mask: rgba(0, 0, 0, 0.5);
  --weak-white: rgba(255, 255, 255, 0.75);
  --black: #000000;
  --error-text: #ed4c4c;
  --error-alert-background: #ed4c4c33;
  --default-alert-background: #eaecf033;
  --link-color: #007bff;
  --bottom-of-autocomplete: 115px;
  --bottom-of-desktop-dropdown-filters: 185px;
  --header-height: 55px;
  --line-color: rgba(255, 255, 255, 0.1);
  --line: 1px solid var(--line-color);
  --chosen-map-style-border: 2px solid #007bff;
  --timeline-background: #b0b4ba;
  --weak-border-line-color: rgba(255, 255, 255, 0.1);

  --pdf-width: 595px;
  --pdf-height: 842px;
}

/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/* User interaction
 * ========================================================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  overflow: hidden;
  width: 100% !important; /* This is necessary because when the antDesign drawer is opened, it changes the body style and directly sets the width */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  min-width: 100%;
  padding: 0 15px;
}

div.ant-collapse-content > .ant-collapse-content-box {
  background: var(--grey-800);
  border: none;
  padding: 0 15px;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border: none;
}

span.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

span.ant-checkbox-inner {
  background-color: rgba(0, 0, 0, 0);
}

div.ant-popover-inner {
  background-color: var(--grey-600);
  border-radius: 5px;
}

div.ant-popover-arrow {
  display: none;
}

.ant-btn-icon-only {
  flex: none;
  padding: 10px;
}

div.ant-select-dropdown {
  background: var(--grey-600);
  border-radius: 5px;
}

div.ant-select-item {
  padding: 0;
}

.ant-select-item-option {
  background: var(--grey-600);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-select-item-option:last-child {
  border-bottom: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
  background-color: var(--grey-700);
}

.ant-select-focused {
  background: var(--grey-600);
  border-radius: 5px;
}

.side-bar__close {
  top: 100px !important;
}

.anticon svg {
  fill: var(--white);
}

.anticon-caret-down svg {
  vertical-align: middle;
}

div.ant-picker-range {
  background: none;
  border-radius: 5px;
  border: none;
  width: 100%;
}

div.ant-picker-range:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.ant-picker-input > input {
  color: var(--white) !important;
}

.ant-modal-close {
  display: none;
}

span.ant-picker-clear {
  background: var(--grey-800);
}

div.ant-modal-content {
  box-shadow: none;
  background: none;
  width: inherit;
  display: flex;
  justify-content: center;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-modal-body {
  padding: 0;
  width: inherit;
}

.ant-modal-header {
  display: none;
}

.map-locale-controls {
  display: none;
}

div.toggle-3d {
  display: none;
}

div.draw-feature.toolbar-item {
  display: none;
}

.map-draw-controls {
  display: none;
}

/*
  Nested here are used to hide unnecessary kepler functions for working with feature polygons that might break the logic.
  Why nested because i didn't find other way.
 */
.feature-action-panel > div > div:nth-child(1),
.feature-action-panel > div > div:nth-child(2) {
  display: none;
}

div.kepler-gl {
  z-index: 2;
  position: absolute;
  right: 0;
}

.kepler-gl .bottom-widget--inner {
  background: #b0b4ba;
}

.kepler-gl .bottom-widget__field {
  color: #111827;
}

.kepler-gl .bottom-widget__field span {
  color: #111827;
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
}

div.kepler-gl .timeline-container {
  width: 100% !important;
}

div.kepler-gl g.tick text {
  fill: #252b39;
  font-size: 10px;
  font-weight: 500;
}

.kepler-gl .bottom-widget--inner .playback-controls {
  position: relative;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.kepler-gl .time-range-slider__container {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.kepler-gl .bottom-widget--inner .selection {
  stroke: none;
  fill: #dadfe7;
}

.kepler-gl .bottom-widget--inner path.handle--custom {
  fill: #059669;
}

.kepler-gl .time-range-slider .histogram-bars rect {
  fill: #6b7280;
  rx: 2px;
  ry: 2px;
}

.kepler-gl .time-range-slider .histogram-bars rect.in-range {
  fill: #00112a;
}

.kepler-gl .floating-time-display {
  display: none;
}

.kepler-gl .map-control-button {
  background: #4c5564;
  color: white;
  border-radius: 5px;
}

div.map-control {
  top: 45px;
}

div.map-popover {
  overflow: hidden;
  box-shadow: none;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

div.map-popover:hover {
  background-color: rgba(0, 0, 0, 0);
}

.map-popover > div {
  opacity: 0;
}
/*
  !important need to shadow kepler-gl default styles
*/
div.bottom-widget__y-axis {
  display: none;
}

/*
  Its the name of the class that is the main container of the timeline block
*/
.jYDrHE {
  width: 100%;
}

div.bottom-widget--container {
  padding: 0;
  transition: all 0.3s;
  width: 100%;
}

.ant-modal {
  width: 94% !important;
  display: flex;
  justify-content: center;
}

.show-legend {
  display: none !important;
}

.rsme-embed > div {
  display: flex;
  justify-content: center;
}

div.notification-item {
  display: none;
}

span.ant-checkbox-checked:after {
  border-color: var(--primary-color);
}

div.time-widget--top .sc-jSUZER:nth-child(3) {
  /* its class for default kepler timeline open | close button */
  display: none;
}

/*
  fix widget tooltip
*/
.kepler-gl .bottom-widget--inner .playback-controls .place-top::after {
  background-color: inherit;
}

.kepler-gl .bottom-widget--inner .playback-controls .place-top::before {
  display: none;
}

div.kg-range-slider__slider {
  display: none;
}

@media (min-width: 768px) {
  .kepler-gl .bottom-widget--inner .playback-controls {
    position: absolute;
    top: 15px;
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    text-transform: capitalize;
  }

  div.map-control {
    top: unset;
  }

  div.kg-range-slider__slider {
    display: unset;
  }

  .ant-modal {
    width: 80% !important;
  }

  div.ant-picker-range {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  span.ant-picker-clear {
    background: var(--grey-700);
  }
}

.home_dashboard__2Ukmz {
  height: calc(100vh - var(--header-height));
  position: relative;
  overflow: hidden;
}

.home_dashboardSideBarOpen__3y-kX {
  overflow: unset;
}

.home_searchBox__1XpXs {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.ant-input-wrapper .ant-input-group-addon {
  background: transparent;
  border: none;
  box-shadow: none;
}

.ant-input-group .ant-input {
  background: transparent;
  /*
    !important need to shadow and design default styles
  */
  border: none !important;
  box-shadow: none !important;
  color: var(--white);
}

.ant-input-group .ant-input::-webkit-input-placeholder {
  color: var(--white);
}

.ant-input-group .ant-input::placeholder {
  color: var(--white);
}

.home_filtersContainer__PhOI0 {
  min-width: 100%;
  transition: all 0.3s;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  z-index: 3;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.home_mapContainer__r26WC {
  position: relative;
  width: 100%;
  height: 100%;
}

.home_content__3sSXU {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.home_toggleSideBarBtn__1inic {
  display: none;
}

.home_mapContainer__r26WC {
  position: relative;
  z-index: 1;
}

.home_eventModal__2pI2f {
  width: 80%;
}

.home_mapLabel__36p2v {
  display: none;
}

button.home_submitButton__1-_2J {
  display: none;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
  background: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 5px;
}

button.home_submitButton__1-_2J:hover,
button.home_submitButton__1-_2J:focus,
button.home_submitButton__1-_2J:active {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.home_keplerMapContainer__2VBjM {
  width: 100%;
  height: 100%;
}

.home_aboutPopup__39Chv {
  padding: 20px;
  border-radius: 0 0 15px 15px;
  border-top: 2px solid var(--primary-color);
  background: var(--grey-700);
  color: white;
  position: relative;
}

.home_aboutPopupHeader__mSmq- {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
}

.home_aboutPopupHeaderTitle__1YLYA {
  display: flex;
  flex-direction: column;
}

.home_aboutPopupTitle__3-V_N {
  color: var(--white);
  margin: 0;
  font-size: 18px;
}

.home_aboutPopupTitleSub__2OdEP {
  margin: 0;
}

.home_mapIconBlock__8dhAq {
  background: var(--opacity-primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_aboutPopupFooter__13XdY {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 15px;
  background: var(--grey-800);
  left: 0;
  grid-gap: 5px;
  gap: 5px;
  position: fixed;
  bottom: 0;
  -webkit-transform: none;
          transform: none;
  border-radius: 0;
}

.home_footerLink__YxDTg {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
}

.home_divideLine___KNQw {
  margin: 15px 0;
  background: var(--white);
  opacity: 0.1;
}

.home_closeAboutPopup__d9-A9 {
  display: none;
}

@media (min-width: 768px) {
  .home_aboutPopupFooter__13XdY {
    position: absolute;
    bottom: 20px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    border-radius: 0 0 15px 15px;
  }

  .home_closeAboutPopup__d9-A9 {
    display: block;
    position: fixed;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s;
    z-index: 1001; /* z-index: 1001 here for the shadow antd modal z-index */
  }

  .home_closeAboutPopupHidden__1Wxc3 {
    display: none;
  }

  .home_closeAboutPopup__d9-A9:hover {
    opacity: 0.8;
  }

  .home_keplerMapContainer__2VBjM {
    width: unset;
  }

  .home_keplerFull__p4i5b {
    width: 100%;
  }

  .home_keplerMin__2qkDJ {
    -webkit-animation: home_minifyKepler__2TysP 0.3s forwards linear;
            animation: home_minifyKepler__2TysP 0.3s forwards linear;
  }

  @-webkit-keyframes home_minifyKepler__2TysP {
    0% {
      width: 100%;
    }
    99% {
      width: 100%;
    }
    100% {
      width: calc(100% - 370px);
    }
  }

  @keyframes home_minifyKepler__2TysP {
    0% {
      width: 100%;
    }
    99% {
      width: 100%;
    }
    100% {
      width: calc(100% - 370px);
    }
  }

  button.home_submitButton__1-_2J {
    display: block;
  }

  .home_mapLabel__36p2v {
    padding: 10px 23px;
    position: absolute;
    left: 400px;
    top: 15px;
    background: rgba(29, 41, 57, 0.05);
    border: 1px solid var(--white);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 3;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--grey-700);
    transition: all 0.3s;
    display: block;
  }

  .home_mapLabelFull__vFm9y {
    left: 30px;
  }

  .home_content__3sSXU {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .home_filtersContainer__PhOI0 {
    max-width: 370px;
    height: 100%;
    min-width: 370px;
  }

  .home_filtersContainerClose__1uugw {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }

  .home_sideBarImageClose__2NCZv {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
    transition: all 0.3s;
  }

  .home_toggleSideBarBtn__1inic {
    outline: none;
    cursor: pointer;
    position: absolute;
    right: -20px;
    border: none;
    width: 20px;
    height: 30px;
    border-radius: 0 5px 5px 0;
    top: 20px;
    background: var(--grey-700);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search_buttonImageStyle__2RvLd {
  text-align: center;
  vertical-align: baseline;
}

button.search_searchHeaderBtn__1sa3p {
  flex: none;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: none;
}

div.search_checkBoxGroup__1-SMH {
  max-height: calc(100vh - var(--bottom-of-desktop-dropdown-filters));
  overflow: auto;
  padding: 10px;
}

.search_searchHeaderBtn__1sa3p.ant-btn {
  background: var(--blur-color);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: none;
  box-shadow: none;
  border-radius: 5px;
  color: var(--white);
}

.search_searchHeaderBtn__1sa3p.ant-btn:hover {
  background: var(--grey-600);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: none;
  box-shadow: none;
  border-radius: 5px;
  color: var(--white);
}

.search_inputBox__1ud1v {
  margin: 10px;
  margin-right: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.search_searchInput__3tblx.ant-input-group-wrapper {
  display: block;
  background: var(--blur-color);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 5px;
  overflow: hidden;
  padding: 5px;
}

.search_searchInput__3tblx.ant-input-group-wrapper .ant-input {
  color: var(--weak-white);
  padding-left: 0;
}

.search_container__1ymdk {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  z-index: 5;
  transition: all 0.3s;
}

.search_clearIcon__1Zs55 svg {
  /* I used nested styles to get to the svg element in ant design */
  width: 12px;
  height: 12px;
  /* 10px is not enough, because 12px */
}

.search_autocompleteFoundSearchItem__KjysI {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: var(--white);
  padding: 10px 15px;
  cursor: pointer;
  background: var(--grey-600);
  border-bottom: var(--line);
  grid-gap: 0;
  gap: 0;
  flex-direction: column;
  align-items: start;
}

.search_firstAutoCompleteItemInfo__1v7YZ {
  grid-gap: 10px;
  gap: 10px;
}

.search_descriptionText__2xWZI {
  margin: 0;
  margin-left: 15px;
  color: var(--light-gray);
  font-size: 12px;
  display: flex;
}

.search_descriptionText__2xWZI p,
.search_descriptionText__2xWZI b {
  margin: 0;
  white-space: pre;
}

.search_autocompleteFoundSearchItem__KjysI:last-child {
  border-bottom: none;
}

.search_foundSearchItemInfo__1GXtP {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.search_foundSearchItemInfoImg__3xI2L {
  width: 10px;
  height: 15px;
  margin-right: 5px;
}

.search_foundSearchItemInfoText__1Yy0H {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}

.search_foundSearchItemInfoText__1Yy0H p,
.search_foundSearchItemInfoText__1Yy0H b {
  margin: 0;
  white-space: pre;
}

.search_searchFilterBlock__g96eq {
  display: flex;
  flex-direction: column;
  background: var(--grey-800);
  grid-gap: 10px;
  gap: 10px;
}

.search_searchFilterSelectBlock__1xQZF {
  display: flex;
  flex-direction: column;
  order: 2;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 10px;
}

.search_footerFilters__1zc8t {
  order: 3;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search_resetButton__2Q_5s {
  background: none;
  border: none;
  color: var(--white);
  margin-right: 5px;
  cursor: pointer;
}

label.search_mapFrameEvents__296Ll {
  color: var(--white);
}

.search_searchDatePickerBlock___xPmU {
  order: 1;
  position: relative;
  margin: 15px;
  background: var(--grey-800);
  border-radius: 5px;
  padding: 5px;
  border: var(--line);
}

.search_filtersButton__1zHLe {
  margin: 5px;
}

.search_filterTitle__3mjBw {
  color: var(--white);
  font-size: 14px;
}

button.search_filterCloseBtn__QS95Q {
  background: none;
  border: none;
}

button.search_filterCloseBtn__QS95Q:hover,
button.search_filterCloseBtn__QS95Q:active,
button.search_filterCloseBtn__QS95Q:focus {
  background: none;
}

.search_searchDatePickerButton__2nroj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: end;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--grey-500);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--white);
  cursor: pointer;
  z-index: 2;
}

button.search_categoriesCheckboxButton__3L2VS {
  display: flex;
  background: rgba(0, 0, 0, 0);
  border-radius: 5px;
  color: var(--white);
  border: var(--line);
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

button.search_categoriesCheckboxButton__3L2VS:focus,
button.search_categoriesCheckboxButton__3L2VS:hover,
button.search_categoriesCheckboxButton__3L2VS:active {
  background: var(--line-color);
  border: var(--line);
  color: var(--white);
}

.search_greenCircle__3-I8E {
  display: inline-block;
  background: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.search_greenCircle__3-I8E:empty {
  display: none;
}

.search_selectAll__3sDFH {
  color: var(--white);
  cursor: pointer;
}

.search_markAllCategoriesLine__12oDi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search_markAllCategoriesBlock__jfRHf {
  display: flex;
  align-items: center;
}

.search_markAllCategoriesMark__JeUlG {
  background: var(--primary-color);
  border: 1px solid var(--grey-500);
  border-radius: 5px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.search_markAllCategoriesMark__JeUlG span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.search_markAllCategoriesMark__JeUlG svg {
  width: 10px;
  fill: var(--white);
}

button.search_applyCheckedList__2NbyK,
button.search_applyCheckedList__2NbyK:hover {
  background: none;
  box-shadow: none;
  border: none;
  color: var(--white);
}

.search_applyCheckedList__2NbyK:hover {
  background: none;
  color: white;
  border: none;
}

.search_checkBoxGroupRow__3m380 {
  position: relative;
  padding: 10px 0;
  color: var(--white);
  border-bottom: var(--line);
}

.search_checkBoxGroupRow__3m380:last-child {
  border-bottom: none;
}

.search_checkBoxGroupRow__3m380 label {
  color: var(--white);
}

.search_applyCheckedListClose__2MbGg {
  opacity: 0;
  z-index: -1;
}

.search_filterContainer__24krZ {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--grey-800);
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 5;
}

.search_filterWrapperOpen__5TM6H {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.search_filterHeader__2hF-Y {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.search_checkBoxGroupPopupOverlay__eQN7P {
  padding-top: 0;
}

.search_filterHeaderWrapper__1BU2m {
  background: var(--grey-800);
}

button.search_filterCloseButton__2Zyni:active,
button.search_filterCloseButton__2Zyni:focus,
button.search_filterCloseButton__2Zyni:hover {
  position: absolute;
  right: 25px;
}

.search_wrapperDatePicker__lT4fo {
  background: var(--grey-700);
}

@media (max-width: 768px) {
  .search_fullModeContainer__205ax {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: var(--grey-700);
    padding-right: 10px;
  }

  .search_filterContent__9Dz8d {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .search_filterBody__3sURw {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 25px;
  }

  .search_searchFilterSelectBlock__1xQZF {
    grid-gap: 0;
    gap: 0;
  }

  .search_expandIcon__3jh0T {
    transition: all 0.3s;
  }

  .search_expandIconOpen__54ZbB {
    -webkit-transform: translateY(-50%) rotateZ(90deg) !important;
            transform: translateY(-50%) rotateZ(90deg) !important;
  }

  .search_checkBoxGroupPopover__f-qWi {
    display: none !important;
  }

  .search_checkBoxGroup__1-SMH {
    width: 100%;
    max-height: 350px;
  }

  div.search_checkBoxCollapse__2RXcj {
    position: relative;
    display: block;
    background: var(--grey-800);
    border: none;
  }

  .search_checkBoxCollapse__2RXcj::after {
    content: "";
    position: absolute;
    width: 100vh;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    left: -10px;
    bottom: 0;
  }

  .search_checkBoxPanel__1jEPD {
    background: var(--grey-800);
    border: none !important;
  }

  .search_footerFilters__1zc8t {
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .search_checkBoxCollapse__2RXcj {
    display: none;
  }

  .search_searchInput__3tblx.ant-input-group-wrapper {
    background: var(--line-color);
  }

  .search_inputBox__1ud1v {
    margin: 0;
    border: var(--line);
    border-radius: 5px;
  }

  .search_filterContainer__24krZ {
    position: relative;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  .search_drawerContent__3Qzaq {
    padding: 0;
  }

  .search_filterHeader__2hF-Y {
    display: none;
  }

  .search_filterHeaderWrapper__1BU2m {
    display: none;
  }

  .search_container__1ymdk {
    flex-direction: column;
    padding: 10px 15px;
    background: var(--grey-700);
    align-items: unset;
  }

  button.search_searchHeaderBtn__1sa3p {
    display: none;
  }

  .search_searchFilterBlock__g96eq {
    background: var(--grey-700);
  }

  .search_searchFilterSelectBlock__1xQZF {
    flex-direction: row;
    justify-content: space-between;
    order: 1;
    padding: 0;
  }

  .search_footerFilters__1zc8t {
    padding: 0;
    order: 3;
  }

  .search_searchDatePickerBlock___xPmU {
    margin: 0;
    order: 2;
    border: none;
    padding: 0;
    background: none;
  }

  .search_wrapperDatePicker__lT4fo {
    background: none;
    order: 3;
  }
}

.keplergl_mapChanger__1fQr6 {
  color: var(--white);
  padding: 10px 15px;
}

.keplergl_mapHub__1jE5d {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.keplergl_mapHeader__gwl4U {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.keplergl_maps__1iaWa {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.keplergl_openMoreMapsCardClose__2fRoi {
  display: none;
}

.keplergl_mapCard__3iM6- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 40px;
  height: 64px;
  margin: 7px;
  font-size: 12px;
  cursor: pointer;
}

.keplergl_mapCardImg__eWeuN {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.keplergl_mapHubLegacyOpen__2lIlL {
  max-height: 178px;
}

.keplergl_mapHubLegacyClose__1VKko {
  max-height: 0;
}

.keplergl_mapHubLegacy__3kvlt {
  transition: all 0.3s;
  overflow: hidden;
}

.keplergl_mapDividerClose__2MOd8 {
  display: none;
}

.keplergl_mapDividerOpen__2alIF {
  display: block;
}

.keplergl_mapDivider__2VMqF {
  width: 224px;
  height: 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 10px 0;
}

.keplergl_mapControlsButtonsContainer__11_RK {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.keplergl_mapControlsButtons__11jCv {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

button.keplergl_mapControlButton__1rL3l:hover {
  border-color: var(--default-grey);
  background: var(--default-grey);
}

.keplergl_closeMapsBtn__jsv5N {
  cursor: pointer;
}

.keplergl_openMapsButton__3ox1R {
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  -webkit-filter: drop-shadow(1px 1px 11px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(1px 1px 11px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  cursor: pointer;
}

.keplergl_openMapsButton__3ox1R img {
  width: 100%;
  height: 100%;
}

.keplergl_timelineControlButton__2e3Zv {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 40px;
  height: 20px;
  border-radius: 5px 5px 0 0;
  background: var(--timeline-background);
  border: none;
  cursor: pointer;
  z-index: -1;
}

.keplergl_timelineControlImage__1pkqA {
  width: 10px;
  transition: all 0.3s;
}

.keplergl_timelineControlImageClose__3YEvI {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.keplergl_timelineBlock__2leMk {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.keplergl_timelineBlockClose__39kjf {
  -webkit-transform: translateY(calc(100% - 20px));
          transform: translateY(calc(100% - 20px));
}

.keplergl_chosenMap__1ETuD {
  border: var(--chosen-map-style-border);
  border-radius: 5px;
}

.keplergl_mapControllerWrapper__1Xbef {
  position: fixed;
  bottom: 35px;
  right: 10px;
  z-index: 2;
  transition: all 0.3s;
}

div.keplergl_tooltip__2JBoE {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 1;
  margin: 0;
  word-break: break-word;
}

div.keplergl_keplerTooltipContainer__13ijR {
  display: block;
  width: 100%;
  position: unset;
  left: unset;
  -webkit-transform: unset;
          transform: unset;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 15px;
  background: var(--grey-800);
  border-radius: 5px;
}

div.keplergl_tooltipInfoContainer__e1LOI {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

div.keplergl_tooltipHeader__1KzXd {
  position: relative;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 5px 0;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

div.keplergl_tooltipGraphicContentLevel__1BDXA {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 20px;
}

time.keplergl_tooltipTime__2szlQ {
  flex: none;
  position: relative;
  right: unset;
}

.keplergl_editorPopup__ahEVY {
  border-radius: 10px;
}

.keplergl_editorItem__1ANsP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: var(--white);
  cursor: pointer;
}

.keplergl_editorItem__1ANsP:hover {
  opacity: 0.8;
}

.keplergl_selectedEditorItem__2xlJv {
  background: var(--grey-500);
}

.keplergl_keplerMapButtons__30qqw {
  position: absolute;
  right: 12px;
  top: 58px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  align-items: end;
}

button.keplergl_keplerEditorModeBtn__3Tr-- {
  display: none;
}

.keplergl_keplerMapButton__2HlO_ {
  padding: 7px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  background: #4c5564;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
}

.keplergl_expandContainer__38LZX {
  height: 100%;
  border-radius: 5px;
  background: #4c5564;
  transition: all 0.3s;
}

.keplergl_expandPopupContent__2Uh4v {
  position: relative;
  padding: 10px;
  color: white;
  background: var(--grey-600);
  border-radius: 10px;
  max-width: 350px;
}

.keplergl_databaseContainer__vR_ej {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.keplergl_legendPopupDBName__2pAR7 {
  font-size: 16px;
  font-weight: 500;
}

.keplergl_legendPopupDBCategory__2esVR {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.keplergl_DBCategoryName__10bOz {
  color: var(--weak-white);
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}

.keplergl_DBCategoryColor__3uX1i {
  width: 20px;
  height: 10px;
  flex: none;
}

.keplergl_popUpCategoriesList__1ymkV {
  padding: 5px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.keplergl_aboutPopupTitle__3CHWM {
  color: var(--white);
  margin: 0;
  font-size: 18px;
}

.keplergl_aboutPopupTitleSub__30kd0 {
  margin: 0;
}

.keplergl_minimizeBtn__3XjSW {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-gray);
  cursor: pointer;
}

.keplergl_hideExpandBtn__YG6eQ {
  display: none;
}

.keplergl_expandBtn__Ygy-Y {
  padding: 7px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .keplergl_keplerMapButtons__30qqw {
    top: 12px;
  }

  button.keplergl_keplerEditorModeBtn__3Tr-- {
    display: flex;
    text-transform: capitalize;
  }
}

div.event_disableScroll__1hUC- {
  overflow-y: hidden;
}

.event_container__2dtMd {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.event_contentContainer__2s4m_ {
  position: relative;
  padding: 20px;
  background: var(--grey-750);
  overflow-y: scroll;
  max-height: 500px;
  display: inline-block;
  flex-grow: 2;
  flex-basis: 400px;
}

.event_contentContainer__2s4m_::-webkit-scrollbar {
  display: none;
}

.event_categoriesContainer__1nvg6 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.event_categoryItem__s0M8S {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 5px;
  color: var(--weak-white);
  align-items: center;
  font-size: 12px;
}

.event_categoryItemColorDot__3Cyxu {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
}

.event_expandContainer__25BQ2 {
  background: var(--grey-800);
  padding: 20px;
  flex-basis: 200px;
  flex-grow: 1;
}

.event_infoGridContainer__149ml {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-width: 280px;
}

.event_typeOfProperty__3vSdB {
  font-weight: 400;
  font-size: 10px;
  color: var(--weak-white);
  word-break: break-word;
}

.event_loadingWidget__2QMXE {
  position: relative;
  width: 100%;
  height: 400px;
}

.event_loadingText__2XsXT {
  color: var(--white);
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
}

.event_loadingBlock__2wz5g {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.event_mainInfo__2tGqz {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: 12px;
  color: var(--white);
}

.event_divideLine__3ly8u {
  display: block;
  width: 100%;
  height: 0;
  opacity: 0.05;
  border: 1px solid var(--white);
  margin: 15px 0;
}

.event_mainInfoLink__1aabF {
  color: var(--link-color);
}

.event_text__31zdP {
  font-weight: 400;
  font-size: 12px;
  color: var(--weak-white);
}

.event_violenceLevel__2Z0vV,
.event_entry__bJVjW {
  font-size: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.event_violenceLevel__2Z0vV {
  color: var(--error-text);
  background: var(--error-alert-background);
}

.event_entry__bJVjW {
  display: none;
}

.event_categories__1a6JV {
  font-size: 10px;
}

.event_disableLinkMode__2eUf5 {
  pointer-events: none;
  cursor: default;
  color: var(--white);
}

.event_time__2iufF {
  font-weight: 400;
  font-size: 12px;
  color: var(--weak-white);
  position: absolute;
  right: 15px;
}

.event_criteriaContainer__3yOf3 {
  position: relative;
}

.event_criteriaInfo__KBhhx {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  margin: 5px 0;
}

.event_itemContainer__2Aqjo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}

.event_itemContainer__2Aqjo:first-child {
  border-top-left-radius: 15px;
}

.event_itemContainer__2Aqjo:last-child {
  border-bottom-right-radius: 15px;
}

.event_itemContainer__2Aqjo:nth-child(3) {
  display: none;
}

.event_itemContainer__2Aqjo:nth-child(2) {
  border-top-right-radius: 15px;
}

.event_itemContainer__2Aqjo:nth-child(8) {
  border-bottom-left-radius: 15px;
}

.event_closeButton__2k5w8 {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background: white;
  width: 25px;
  height: 25px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.event_closeButton__2k5w8:hover {
  background: var(--default-grey);
}

.event_sensitiveWarningContainer__2BGeu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: lightgray;
  transition: all 0.3s;
}

.event_sensitiveWarningBlock__hAkVX {
  padding: 0 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.event_sensitiveIcon__f1jT3 {
  width: 30px;
  margin: 15px auto;
}

.event_sensitiveBlockPreviewBtn__2hp5n {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px auto;
}

button.event_sensitiveBlockPreviewBtn__2hp5n:hover,
button.event_sensitiveBlockPreviewBtn__2hp5n:active,
button.event_sensitiveBlockPreviewBtn__2hp5n:focus {
  background: gray;
  color: white;
  border: white;
}

@media (max-width: 768px) {
  .event_container__2dtMd {
    border-radius: 0;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .event_infoGridContainer__149ml {
    grid-template-columns: repeat(3, 1fr);
    min-width: 320px;
  }

  .event_itemContainer__2Aqjo:nth-child(3) {
    border-top-right-radius: 15px;
  }

  .event_itemContainer__2Aqjo:nth-child(7) {
    border-bottom-left-radius: 15px;
  }

  .event_itemContainer__2Aqjo:nth-child(2) {
    border-top-right-radius: 0;
  }

  .event_itemContainer__2Aqjo:nth-child(8) {
    border-bottom-left-radius: 0;
  }

  .event_itemContainer__2Aqjo:nth-child(3) {
    display: flex;
  }

  .event_closeButton__2k5w8 {
    display: flex;
  }
}

.categories_categoriesContainer__2zKCw {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
}

.categories_categoryItem__xJnbE {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  color: var(--white);
  border: var(--line);
}
.events_regionEventsContainer__1Oygq {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  z-index: 4;
}

.events_regionEventsItems__29DGa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0px;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - var(--bottom-of-autocomplete));
  border-radius: 10px 10px 0 0;
  background: var(--grey-700);
  padding-top: 15px;
}

.events_swipeBlock__2OeOE {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 1;
}

.events_regionEventsContainerFullOpen__H0oZ8 {
  top: var(--bottom-of-autocomplete);
}

.events_regionEventsContainerOpen__1VLEA {
  -webkit-transform: translate(0, -25%);
          transform: translate(0, -25%);
  top: 100%;
}

.events_regionEventsContainerClose__3MeIF {
  top: 100%;
}

.events_regionEventsContainerFullOpen__H0oZ8 .events_regionEventsItems__29DGa {
  top: 0;
  border-radius: 0;
}

.events_detailOfHistoryItem__1spib {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-grow: 0;
  color: var(--white);
  padding: 15px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--grey-600);
  border-radius: 5px;
  word-break: break-word;
  margin: 5px 0;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.events_historyItemDate__3TcrE {
  font-size: 10px;
  align-self: center;
  flex: none;
  opacity: 0.5;
  color: var(--weak-white);
}

.events_noDataWrapper__20isl {
  width: 100%;
  height: 100%;
  display: table;
}

.events_noDataText__3Izqv {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  color: var(--white);
}

.events_historyItemTitle__BFIJM {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.events_regionEventsItemsButton__31awi {
  display: block;
  border: 2px solid var(--grey-500);
  background: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 35px;
}

.events_regionEventsItemsButtonClose__1eDcc {
  display: none;
}

.events_itemWrapper__2tB50 {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.events_itemWrapper__2tB50:hover {
  background: var(--blur-color);
}

@media (min-width: 768px) {
  @supports (-webkit-appearance:none) {
    .events_regionEventsContainer__1Oygq {
      height: -webkit-fill-available;
    }
  }

  .events_swipeBlock__2OeOE {
    z-index: -1 !important;
  }

  .events_regionEventsItemsButton__31awi {
    display: none;
  }

  .events_regionEventsContainer__1Oygq {
    position: relative;
    top: unset;
    -webkit-transform: unset;
            transform: unset;
  }

  .events_regionEventsItems__29DGa {
    padding-top: 0;
    border-radius: 0;
    background: var(--grey-800);
    height: 100%;
  }

  .events_itemWrapper__2tB50 {
    border-bottom: var(--line);
    border-radius: 0;
  }

  .events_detailOfHistoryItem__1spib {
    margin: 0;
    background: none;
    padding: 15px 5px;
  }

  .events_itemWrapper__2tB50:last-child {
    border-bottom: none;
  }
}

.loading_fullScreenLoadContainer__1_EEo,
.loading_absoluteLoadContainer__32WQX {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--background-mask);
  width: 100%;
  height: 100%;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white);
}

.loading_absoluteLoadContainer__32WQX {
  position: absolute;
}

.loading_content__3UCR8 {
  text-align: center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.exportPdf_pdfDocumentContainer__10r0Y {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: white;
}

.exportPdf_pdfMap__28KxL {
  position: relative;
  width: calc(var(--pdf-width) * 3);
  height: calc(var(--pdf-height) * 3);
}

.exportPdf_mapImage__3k14E {
  width: 100%;
}

.exportPdf_logoImage__2no-2 {
  width: 170px;
  position: absolute;
  background: var(--grey-800);
}

.exportPdf_filters__2cxUo {
  display: flex;
  flex-direction: column;
  width: var(--pdf-width);
  height: var(--pdf-height);
}

.exportPdf_searchQuery__TgorB {
  margin: 0;
}

.exportPdf_searchQueryTitle__Sdq-C {
  font-weight: bold;
  margin: 0;
}

.exportPdf_selectedFilters__aJnqd {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.exportPdf_listContainer__1lcMs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exportPdf_listStyle__2T1gr {
  padding: 5px;
}

.exportPdf_listItem__inoNQ {
  border-bottom: 1px solid var(--default-grey);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.exportPdf_listHeader__mvRPF {
  font-weight: bold;
}

.exportPdf_filtersContainer__30Q6v {
  display: flex;
  align-items: start;
}

.exportPdf_filtersContainerHeader__1vfMp {
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  margin: 0;
}

div.exportPdf_modal__2Yi9C {
  display: flex;
  flex-direction: column;
  top: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.exportPdf_modalContent__hLiLz {
  text-align: center;
  color: var(--white);
}

.adaptiveModal_mobileModalBackButton__1_FOk {
  color: var(--white);
}

.adaptiveModal_desktopModal__1frXh {
  display: none;
}

.adaptiveModal_closeButton__twBck {
  border-radius: 50%;
  background: var(--white);
  border: none;
  width: 35px;
  height: 35px;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.adaptiveModal_mobileModal__zhzSy {
  display: block;
}

@media (min-width: 768px) {
  .adaptiveModal_desktopModal__1frXh {
    display: block;
  }

  .adaptiveModal_mobileModal__zhzSy {
    display: none;
  }

  .adaptiveModal_closeButton__twBck {
    display: flex;
  }
}

.submitEvent_submitEventForm__ruDpX {
  background: white;
  padding: 25px;
  border-radius: 5px;
}

.menu_openBtn__JBHr0 {
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
  margin-right: 20px;
}

.menu_closeBtn__kLvNN {
  position: relative;
  width: 15px;
  height: 15px;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
}

.menu_closeBtn__kLvNN:hover {
  opacity: 1;
}

.menu_closeBtn__kLvNN:before,
.menu_closeBtn__kLvNN:after {
  position: absolute;
  top: 0;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: var(--white);
}

.menu_closeBtn__kLvNN:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.menu_closeBtn__kLvNN:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.menu_header__2hGVZ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;
}

.menu_navItem__1BDUT {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-300);
}

.menu_activeNav__3BNG1 {
  color: var(--white);
}

.menu_logo__1NDKU {
  width: 150px;
}

.menu_navigation__1eIW0 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.header_header__v9FKw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: var(--grey-800);
  height: var(--header-height);
}

.header_logo__1X7aW {
  width: 170px;
}

.layout_layout__2VB0u {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 6;
}

.about_aboutWrapper__3mVZI {
  padding: 25px 50px 100px 25px;
  background: var(--grey-800);
  width: 100%;
  height: calc(100vh - var(--header-height));
  overflow: auto;
  border-top: 1px solid var(--weak-border-line-color);
}

.about_innerContainer__uhJJz {
  max-width: 800px;
  margin: 0 auto;
}

.about_about__38NGA {
  color: white;
}

.about_aboutContainer__1ORoC {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--weak-border-line-color);
}

.about_list__2NKoX {
  list-style: disc;
  margin-left: 25px;
}

.about_list__2NKoX li {
  margin: 5px 0;
}

.about_collapsePanel__2yQqC {
  background: var(--grey-800);
  border-bottom: none !important;
}

.about_collapsePanel__2yQqC .ant-collapse-header {
  background: var(--grey-700) !important;
  color: white !important;
  font-weight: bold;
}

.about_collapseContent__25fe- {
  color: white;
  padding: 15px;
}

.about_underlinedText__L8x3_ {
  text-decoration: underline;
}
