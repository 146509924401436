:root {
  --primary-color: #059669;
  --opacity-primary-color: #0596694d;
  --blur-color: #1018286b;
  --default-grey: #c6c6c6;
  --light-gray: #b9b9b9;
  --grey-25: #fcfcfd;
  --grey-50: #f9fafb;
  --grey-100: #f2f4f7;
  --grey-200: #eaecf0;
  --grey-300: #d0d5dd;
  --grey-400: #98a2b3;
  --grey-500: #667085;
  --grey-600: #475467;
  --grey-700: #344054;
  --opacity-grey-700: #3440540d;
  --grey-750: #323d4f;
  --grey-800: #1d2939;
  --grey-900: #101828;
  --white: #ffffff;
  --background-mask: rgba(0, 0, 0, 0.5);
  --weak-white: rgba(255, 255, 255, 0.75);
  --black: #000000;
  --error-text: #ed4c4c;
  --error-alert-background: #ed4c4c33;
  --default-alert-background: #eaecf033;
  --link-color: #007bff;
  --bottom-of-autocomplete: 115px;
  --bottom-of-desktop-dropdown-filters: 185px;
  --header-height: 55px;
  --line-color: rgba(255, 255, 255, 0.1);
  --line: 1px solid var(--line-color);
  --chosen-map-style-border: 2px solid #007bff;
  --timeline-background: #b0b4ba;
  --weak-border-line-color: rgba(255, 255, 255, 0.1);

  --pdf-width: 595px;
  --pdf-height: 842px;
}
