.dashboard {
  height: calc(100vh - var(--header-height));
  position: relative;
  overflow: hidden;
}

.dashboardSideBarOpen {
  overflow: unset;
}

.searchBox {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

:global(.ant-input-wrapper .ant-input-group-addon) {
  background: transparent;
  border: none;
  box-shadow: none;
}

:global(.ant-input-group .ant-input) {
  background: transparent;
  /*
    !important need to shadow and design default styles
  */
  border: none !important;
  box-shadow: none !important;
  color: var(--white);
}

:global(.ant-input-group .ant-input::placeholder) {
  color: var(--white);
}

.filtersContainer {
  min-width: 100%;
  transition: all 0.3s;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  z-index: 3;
  height: fit-content;
}

.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.toggleSideBarBtn {
  display: none;
}

.mapContainer {
  position: relative;
  z-index: 1;
}

.eventModal {
  width: 80%;
}

.mapLabel {
  display: none;
}

button.submitButton {
  display: none;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
  background: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 5px;
}

button.submitButton:hover,
button.submitButton:focus,
button.submitButton:active {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.keplerMapContainer {
  width: 100%;
  height: 100%;
}

.aboutPopup {
  padding: 20px;
  border-radius: 0 0 15px 15px;
  border-top: 2px solid var(--primary-color);
  background: var(--grey-700);
  color: white;
  position: relative;
}

.aboutPopupHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 15px;
  margin-bottom: 15px;
}

.aboutPopupHeaderTitle {
  display: flex;
  flex-direction: column;
}

.aboutPopupTitle {
  color: var(--white);
  margin: 0;
  font-size: 18px;
}

.aboutPopupTitleSub {
  margin: 0;
}

.mapIconBlock {
  background: var(--opacity-primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutPopupFooter {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 15px;
  background: var(--grey-800);
  left: 0;
  gap: 5px;
  position: fixed;
  bottom: 0;
  transform: none;
  border-radius: 0;
}

.footerLink {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
}

.divideLine {
  margin: 15px 0;
  background: var(--white);
  opacity: 0.1;
}

.closeAboutPopup {
  display: none;
}

@media (min-width: 768px) {
  .aboutPopupFooter {
    position: absolute;
    bottom: 20px;
    transform: translateY(100%);
    border-radius: 0 0 15px 15px;
  }

  .closeAboutPopup {
    display: block;
    position: fixed;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s;
    z-index: 1001; /* z-index: 1001 here for the shadow antd modal z-index */
  }

  .closeAboutPopupHidden {
    display: none;
  }

  .closeAboutPopup:hover {
    opacity: 0.8;
  }

  .keplerMapContainer {
    width: unset;
  }

  .keplerFull {
    width: 100%;
  }

  .keplerMin {
    animation: minifyKepler 0.3s forwards linear;
  }

  @keyframes minifyKepler {
    0% {
      width: 100%;
    }
    99% {
      width: 100%;
    }
    100% {
      width: calc(100% - 370px);
    }
  }

  button.submitButton {
    display: block;
  }

  .mapLabel {
    padding: 10px 23px;
    position: absolute;
    left: 400px;
    top: 15px;
    background: rgba(29, 41, 57, 0.05);
    border: 1px solid var(--white);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 3;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--grey-700);
    transition: all 0.3s;
    display: block;
  }

  .mapLabelFull {
    left: 30px;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .filtersContainer {
    max-width: 370px;
    height: 100%;
    min-width: 370px;
  }

  .filtersContainerClose {
    transform: translate(-100%, 0);
  }

  .sideBarImageClose {
    transform: rotateZ(180deg);
    transition: all 0.3s;
  }

  .toggleSideBarBtn {
    outline: none;
    cursor: pointer;
    position: absolute;
    right: -20px;
    border: none;
    width: 20px;
    height: 30px;
    border-radius: 0 5px 5px 0;
    top: 20px;
    background: var(--grey-700);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
