div.disableScroll {
  overflow-y: hidden;
}

.container {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.contentContainer {
  position: relative;
  padding: 20px;
  background: var(--grey-750);
  overflow-y: scroll;
  max-height: 500px;
  display: inline-block;
  flex-grow: 2;
  flex-basis: 400px;
}

.contentContainer::-webkit-scrollbar {
  display: none;
}

.categoriesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.categoryItem {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  color: var(--weak-white);
  align-items: center;
  font-size: 12px;
}

.categoryItemColorDot {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
}

.expandContainer {
  background: var(--grey-800);
  padding: 20px;
  flex-basis: 200px;
  flex-grow: 1;
}

.infoGridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-width: 280px;
}

.typeOfProperty {
  font-weight: 400;
  font-size: 10px;
  color: var(--weak-white);
  word-break: break-word;
}

.loadingWidget {
  position: relative;
  width: 100%;
  height: 400px;
}

.loadingText {
  color: var(--white);
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
}

.loadingBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mainInfo {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: 12px;
  color: var(--white);
}

.divideLine {
  display: block;
  width: 100%;
  height: 0;
  opacity: 0.05;
  border: 1px solid var(--white);
  margin: 15px 0;
}

.mainInfoLink {
  color: var(--link-color);
}

.text {
  font-weight: 400;
  font-size: 12px;
  color: var(--weak-white);
}

.violenceLevel,
.entry {
  font-size: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  width: fit-content;
}

.violenceLevel {
  color: var(--error-text);
  background: var(--error-alert-background);
}

.entry {
  display: none;
}

.categories {
  font-size: 10px;
}

.disableLinkMode {
  pointer-events: none;
  cursor: default;
  color: var(--white);
}

.time {
  font-weight: 400;
  font-size: 12px;
  color: var(--weak-white);
  position: absolute;
  right: 15px;
}

.criteriaContainer {
  position: relative;
}

.criteriaInfo {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 5px 0;
}

.itemContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}

.itemContainer:first-child {
  border-top-left-radius: 15px;
}

.itemContainer:last-child {
  border-bottom-right-radius: 15px;
}

.itemContainer:nth-child(3) {
  display: none;
}

.itemContainer:nth-child(2) {
  border-top-right-radius: 15px;
}

.itemContainer:nth-child(8) {
  border-bottom-left-radius: 15px;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background: white;
  width: 25px;
  height: 25px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeButton:hover {
  background: var(--default-grey);
}

.sensitiveWarningContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: lightgray;
  transition: all 0.3s;
}

.sensitiveWarningBlock {
  padding: 0 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sensitiveIcon {
  width: 30px;
  margin: 15px auto;
}

.sensitiveBlockPreviewBtn {
  width: fit-content;
  margin: 5px auto;
}

button.sensitiveBlockPreviewBtn:hover,
button.sensitiveBlockPreviewBtn:active,
button.sensitiveBlockPreviewBtn:focus {
  background: gray;
  color: white;
  border: white;
}

@media (max-width: 768px) {
  .container {
    border-radius: 0;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .infoGridContainer {
    grid-template-columns: repeat(3, 1fr);
    min-width: 320px;
  }

  .itemContainer:nth-child(3) {
    border-top-right-radius: 15px;
  }

  .itemContainer:nth-child(7) {
    border-bottom-left-radius: 15px;
  }

  .itemContainer:nth-child(2) {
    border-top-right-radius: 0;
  }

  .itemContainer:nth-child(8) {
    border-bottom-left-radius: 0;
  }

  .itemContainer:nth-child(3) {
    display: flex;
  }

  .closeButton {
    display: flex;
  }
}
