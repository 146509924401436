.regionEventsContainer {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  z-index: 4;
}

.regionEventsItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0px;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - var(--bottom-of-autocomplete));
  border-radius: 10px 10px 0 0;
  background: var(--grey-700);
  padding-top: 15px;
}

.swipeBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 1;
}

.regionEventsContainerFullOpen {
  top: var(--bottom-of-autocomplete);
}

.regionEventsContainerOpen {
  transform: translate(0, -25%);
  top: 100%;
}

.regionEventsContainerClose {
  top: 100%;
}

.regionEventsContainerFullOpen .regionEventsItems {
  top: 0;
  border-radius: 0;
}

.detailOfHistoryItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-grow: 0;
  color: var(--white);
  padding: 15px;
  width: 100%;
  height: fit-content;
  background: var(--grey-600);
  border-radius: 5px;
  word-break: break-word;
  margin: 5px 0;
  gap: 5px;
  cursor: pointer;
}

.historyItemDate {
  font-size: 10px;
  align-self: center;
  flex: none;
  opacity: 0.5;
  color: var(--weak-white);
}

.noDataWrapper {
  width: 100%;
  height: 100%;
  display: table;
}

.noDataText {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  color: var(--white);
}

.historyItemTitle {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.regionEventsItemsButton {
  display: block;
  border: 2px solid var(--grey-500);
  background: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 35px;
}

.regionEventsItemsButtonClose {
  display: none;
}

.itemWrapper {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.itemWrapper:hover {
  background: var(--blur-color);
}

@media (min-width: 768px) {
  @supports (-webkit-appearance:none) {
    .regionEventsContainer {
      height: -webkit-fill-available;
    }
  }

  .swipeBlock {
    z-index: -1 !important;
  }

  .regionEventsItemsButton {
    display: none;
  }

  .regionEventsContainer {
    position: relative;
    top: unset;
    transform: unset;
  }

  .regionEventsItems {
    padding-top: 0;
    border-radius: 0;
    background: var(--grey-800);
    height: 100%;
  }

  .itemWrapper {
    border-bottom: var(--line);
    border-radius: 0;
  }

  .detailOfHistoryItem {
    margin: 0;
    background: none;
    padding: 15px 5px;
  }

  .itemWrapper:last-child {
    border-bottom: none;
  }
}
